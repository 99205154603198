import React, { useEffect } from "react"
import { withPrefix } from "gatsby"

import "./untappdevents.css"

const Events = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = withPrefix("untappdevents.js");
    script.async = true;
    document.body.appendChild(script);
  });


  return (
    <div id={"events-container"}>
    </div>
  )
}

export default Events