import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSmall from "../components/media/BannerSmall"
import Header from "../components/Header/Header"

import Events from "../components/untappdevents"

const EventsPage = () => (
  <Layout>
    <SEO title="Events" />
    <Header />
    <section className="Hero">
      <BannerSmall />
    </section>
    <main>
      <section>
        <div className="OneColumn">
          <h2>Coming Up at G&C</h2>
          <Events />
        </div>
      </section>
    </main>
  </Layout>
)

export default EventsPage
